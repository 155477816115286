.page-footer {
  background-color: $white;

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__stores {
    padding: 0 0 20px;

    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }

  &__stores-title {
    font-family: $openSans;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__stores-block {
    display: flex;
    flex-direction: column;

    width: 240px;
    margin: auto;
  }

  &__stores-location {
    display: flex;
    align-items: center;

    margin-bottom: 5px;
  }

  &__stores-text {
    margin-left: 10px;

    font-family: $beauSans;
    font-size: 13px;
  }

  &__stores-tel {
    margin-left: 30px;
    font-family: $beauSans;
    font-size: 16px;
    color: $black;
    font-weight: 700;
  }

  &__nav {
    padding: 0 0 20px;

    border-bottom: 1px solid #dedede;
  }

  &__nav-list {
    width: 240px;
    margin: 0 auto;
  }

  &__nav-title {
    font-family: $openSans;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__nav-item {
    margin-bottom: 5px;
  }

  &__nav-link {
    font-family: $openSans;
    font-size: 16px;
    color: $grey;
  }

  &__mode {
    padding: 0 0 20px;

    border-bottom: 1px solid #dedede;
  }

  &__mode-title {
    font-family: $openSans;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__mode-block {
    display: flex;
    flex-direction: column;

    width: 240px;
    margin: 0 auto;
  }

  &__mode-days {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mode-text {
    margin-left: 10px;

    font-family: $beauSans;
    font-size: 13px;
  }

  &__mode-time {
    margin-left: 33px;

    font-family: $beauSans;
    font-size: 16px;
    color: $black;
    font-weight: 700;
  }

  &__about-us {
    display: none;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 150px;

    border-bottom: 1px solid #dedede;
  }

  &__social-item {
    margin-right: 5px;
  }

  &__pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 150px;
  }

  &__pay-text {
    margin-top: 0;

    font-family: $beauSans;
    font-size: 14px;
    color: $grey;
    text-align: center;
  }

  &__pay-img-block {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 800px) {
  .page-footer {

    &__info {
      flex-direction: row;
    }

    &__stores {
      padding: 0 20px 20px;

      border-right: 1px solid #dedede;
    }

    &__stores-title {
      text-align: left;
    }

    &__stores-block {
      width: 190px;
    }

    &__nav {
      flex: 1;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      padding: 0 10px;

      border-top: 1px solid #dedede;
      border-right: 1px solid #dedede;
    }

    &__nav-list {
      width: auto;
      min-width: 110px;

      &__item {
        width: 25%;
      }
    }

    &__nav-title {
      text-align: left;
    }

    &__nav-item {
      text-align: left;
    }

    &__nav-link {
      font-size: 14px;
    }

    &__mode {
      padding: 0 20px;

      text-align: left;

      border-top: 1px solid #dedede;
    }

    &__mode-block {
      width: 160px;
    }

    &__mode-title {
      text-align: left;
    }

    &__mode-days {
      justify-content: normal;

      width: 170px;
    }

    &__footer {
      display: flex;
    }

    &__about-us {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__about-us-age {
      width: 80%;
      margin: 0 auto 20px;

      font-family: $beauSans;
      font-size: 16px;
      font-weight: 700;
    }

    &__about-us-text {
      width: 80%;
      margin: 0 auto;

      font-family: $beauSans;
      font-size: 14px;
      color: $grey;
    }

    &__social {
      flex: 1;

      border: none;
    }

    &__pay {
      flex: 1;

      flex-direction: row;
    }

    &__pay-text {
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .page-footer {

    &__stores {
      width: 25%;
      padding: 0 5% 20px;
    }

    &__stores-block {
      width: auto;
      min-width: 190px;
    }

    &__nav {
      width: 50%;
      padding: 0 5%;
    }

    &__nav-list {
      width: 240px;
    }

    &__nav-link {
      font-size: 16px;
    }

    &__mode {
      width: 25%;
      padding: 0 5%;
    }

    &__mode-block {
      margin: 0;
    }
  }
}
