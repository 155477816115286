// colors

$white: #fff;
$black: #000;
$grey: #959595;

// fonts

$openSans: 'Open Sans', Arial, sans-serif;
$beauSans: 'PF BeauSans Pro', Arial, sans-serif;

// media

$tablet-width: 768px;
$desktop-width: 1024px;
