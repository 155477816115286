// Open Sans Start //

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/OpenSans/opensanslight.woff2') format('woff2'),
  url('../fonts/OpenSans/opensanslight.woff') format('woff'),
  url('../fonts/OpenSans/opensanslight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url('../fonts/OpenSans/opensanslightitalic.woff2') format('woff2'),
  url('../fonts/OpenSans/opensanslightitalic.woff') format('woff'),
  url('../fonts/OpenSans/opensanslightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/OpenSans/opensans.woff2') format('woff2'),
  url('../fonts/OpenSans/opensans.woff') format('woff'),
  url('../fonts/OpenSans/opensans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../fonts/OpenSans/opensansitalic.woff2') format('woff2'),
  url('../fonts/OpenSans/opensansitalic.woff') format('woff'),
  url('../fonts/OpenSans/opensansitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('../fonts/OpenSans/opensanssemibold.woff2') format('woff2'),
  url('../fonts/OpenSans/opensanssemibold.woff') format('woff'),
  url('../fonts/OpenSans/opensanssemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
  url('../fonts/OpenSans/opensanssemibolditalic.woff2') format('woff2'),
  url('../fonts/OpenSans/opensanssemibolditalic.woff') format('woff'),
  url('../fonts/OpenSans/opensanssemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans/opensansbold.woff2') format('woff2'),
  url('../fonts/OpenSans/opensansbold.woff') format('woff'),
  url('../fonts/OpenSans/opensansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../fonts/OpenSans/opensansbolditalic.woff2') format('woff2'),
  url('../fonts/OpenSans/opensansbolditalic.woff') format('woff'),
  url('../fonts/OpenSans/opensansbolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
  url('../fonts/OpenSans/opensansextrabold.woff2') format('woff2'),
  url('../fonts/OpenSans/opensansextrabold.woff') format('woff'),
  url('../fonts/OpenSans/opensansextrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'),
  url('../fonts/OpenSans/opensansextrabolditalic.woff2') format('woff2'),
  url('../fonts/OpenSans/opensansextrabolditalic.woff') format('woff'),
  url('../fonts/OpenSans/opensansextrabolditalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

// Open Sans End //

// PF BeauSans Pro Start //

@font-face {
  font-family: 'PF BeauSans Pro XThin';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.eot');
  src: local('PFBeauSansPro-XThinItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.eot');
  src: local('PFBeauSansPro-LightItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Bold.eot');
  src: local('PFBeauSansPro-Bold'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bold.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Italic.eot');
  src: local('PFBeauSansPro-Italic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Italic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Thin.eot');
  src: local('PFBeauSansPro-Thin'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Thin.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Black.eot');
  src: local('PFBeauSansPro-Black'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Black.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.eot');
  src: local('PFBeauSansPro-ThinItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot');
  src: local('PFBeauSansPro-SemiBoldItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.eot');
  src: local('PFBeauSansPro-BlackItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro XThin';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot');
  src: local('PFBeauSansPro-XThin'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThin.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-XThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro Bbook';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.eot');
  src: local('PFBeauSansPro-Bbook'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot');
  src: local('PFBeauSansPro-SemiBold'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot');
  src: local('PFBeauSansPro-Regular'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.eot');
  src: local('PFBeauSansPro-BoldItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-Light.eot');
  src: local('PFBeauSansPro-Light'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Light.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PF BeauSans Pro Bbook';
  src: url('../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.eot');
  src: local('PFBeauSansPro-BbookItalic'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// PF BeauSans Pro End //
