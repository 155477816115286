html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

[hidden] {
  display: none;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;

  list-style: none;
}

img {
  display: block;

  max-width: 100%;
  height: auto;
}

button {
  font: inherit;
  color: inherit;

  border: 0;

  outline: none;

  background-color: transparent;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}
