/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

[hidden] {
  display: none; }

a {
  text-decoration: none; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

button {
  font: inherit;
  color: inherit;
  border: 0;
  outline: none;
  background-color: transparent; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0); }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/OpenSans/opensanslight.woff2") format("woff2"), url("../fonts/OpenSans/opensanslight.woff") format("woff"), url("../fonts/OpenSans/opensanslight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("../fonts/OpenSans/opensanslightitalic.woff2") format("woff2"), url("../fonts/OpenSans/opensanslightitalic.woff") format("woff"), url("../fonts/OpenSans/opensanslightitalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans"), local("OpenSans"), url("../fonts/OpenSans/opensans.woff2") format("woff2"), url("../fonts/OpenSans/opensans.woff") format("woff"), url("../fonts/OpenSans/opensans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/OpenSans/opensansitalic.woff2") format("woff2"), url("../fonts/OpenSans/opensansitalic.woff") format("woff"), url("../fonts/OpenSans/opensansitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/OpenSans/opensanssemibold.woff2") format("woff2"), url("../fonts/OpenSans/opensanssemibold.woff") format("woff"), url("../fonts/OpenSans/opensanssemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url("../fonts/OpenSans/opensanssemibolditalic.woff2") format("woff2"), url("../fonts/OpenSans/opensanssemibolditalic.woff") format("woff"), url("../fonts/OpenSans/opensanssemibolditalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/OpenSans/opensansbold.woff2") format("woff2"), url("../fonts/OpenSans/opensansbold.woff") format("woff"), url("../fonts/OpenSans/opensansbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../fonts/OpenSans/opensansbolditalic.woff2") format("woff2"), url("../fonts/OpenSans/opensansbolditalic.woff") format("woff"), url("../fonts/OpenSans/opensansbolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"), url("../fonts/OpenSans/opensansextrabold.woff2") format("woff2"), url("../fonts/OpenSans/opensansextrabold.woff") format("woff"), url("../fonts/OpenSans/opensansextrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"), url("../fonts/OpenSans/opensansextrabolditalic.woff2") format("woff2"), url("../fonts/OpenSans/opensansextrabolditalic.woff") format("woff"), url("../fonts/OpenSans/opensansextrabolditalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro XThin';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.eot");
  src: local("PFBeauSansPro-XThinItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.eot");
  src: local("PFBeauSansPro-LightItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Bold.eot");
  src: local("PFBeauSansPro-Bold"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bold.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Italic.eot");
  src: local("PFBeauSansPro-Italic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Italic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Thin.eot");
  src: local("PFBeauSansPro-Thin"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Thin.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Black.eot");
  src: local("PFBeauSansPro-Black"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Black.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.eot");
  src: local("PFBeauSansPro-ThinItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot");
  src: local("PFBeauSansPro-SemiBoldItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.eot");
  src: local("PFBeauSansPro-BlackItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro XThin';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot");
  src: local("PFBeauSansPro-XThin"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThin.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThin.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-XThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro Bbook';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.eot");
  src: local("PFBeauSansPro-Bbook"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Bbook.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot");
  src: local("PFBeauSansPro-SemiBold"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot");
  src: local("PFBeauSansPro-Regular"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.eot");
  src: local("PFBeauSansPro-BoldItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'PF BeauSans Pro';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-Light.eot");
  src: local("PFBeauSansPro-Light"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Light.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'PF BeauSans Pro Bbook';
  src: url("../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.eot");
  src: local("PFBeauSansPro-BbookItalic"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.woff") format("woff"), url("../fonts/PFBeauSansPro/PFBeauSansPro-BbookItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

.page-footer {
  background-color: #fff; }
  .page-footer__info {
    display: flex;
    flex-direction: column; }
  .page-footer__stores {
    padding: 0 0 20px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede; }
  .page-footer__stores-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase; }
  .page-footer__stores-block {
    display: flex;
    flex-direction: column;
    width: 240px;
    margin: auto; }
  .page-footer__stores-location {
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
  .page-footer__stores-text {
    margin-left: 10px;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 13px; }
  .page-footer__stores-tel {
    margin-left: 30px;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 700; }
  .page-footer__nav {
    padding: 0 0 20px;
    border-bottom: 1px solid #dedede; }
  .page-footer__nav-list {
    width: 240px;
    margin: 0 auto; }
  .page-footer__nav-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase; }
  .page-footer__nav-item {
    margin-bottom: 5px; }
  .page-footer__nav-link {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    color: #959595; }
  .page-footer__mode {
    padding: 0 0 20px;
    border-bottom: 1px solid #dedede; }
  .page-footer__mode-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase; }
  .page-footer__mode-block {
    display: flex;
    flex-direction: column;
    width: 240px;
    margin: 0 auto; }
  .page-footer__mode-days {
    display: flex;
    align-items: center;
    justify-content: center; }
  .page-footer__mode-text {
    margin-left: 10px;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 13px; }
  .page-footer__mode-time {
    margin-left: 33px;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 700; }
  .page-footer__about-us {
    display: none; }
  .page-footer__social {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    border-bottom: 1px solid #dedede; }
  .page-footer__social-item {
    margin-right: 5px; }
  .page-footer__pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px; }
  .page-footer__pay-text {
    margin-top: 0;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 14px;
    color: #959595;
    text-align: center; }
  .page-footer__pay-img-block {
    display: flex;
    justify-content: center; }

@media screen and (min-width: 800px) {
  .page-footer__info {
    flex-direction: row; }
  .page-footer__stores {
    padding: 0 20px 20px;
    border-right: 1px solid #dedede; }
  .page-footer__stores-title {
    text-align: left; }
  .page-footer__stores-block {
    width: 190px; }
  .page-footer__nav {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    border-top: 1px solid #dedede;
    border-right: 1px solid #dedede; }
  .page-footer__nav-list {
    width: auto;
    min-width: 110px; }
    .page-footer__nav-list__item {
      width: 25%; }
  .page-footer__nav-title {
    text-align: left; }
  .page-footer__nav-item {
    text-align: left; }
  .page-footer__nav-link {
    font-size: 14px; }
  .page-footer__mode {
    padding: 0 20px;
    text-align: left;
    border-top: 1px solid #dedede; }
  .page-footer__mode-block {
    width: 160px; }
  .page-footer__mode-title {
    text-align: left; }
  .page-footer__mode-days {
    justify-content: normal;
    width: 170px; }
  .page-footer__footer {
    display: flex; }
  .page-footer__about-us {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .page-footer__about-us-age {
    width: 80%;
    margin: 0 auto 20px;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700; }
  .page-footer__about-us-text {
    width: 80%;
    margin: 0 auto;
    font-family: "PF BeauSans Pro", Arial, sans-serif;
    font-size: 14px;
    color: #959595; }
  .page-footer__social {
    flex: 1;
    border: none; }
  .page-footer__pay {
    flex: 1;
    flex-direction: row; }
  .page-footer__pay-text {
    margin-right: 10px; } }

@media screen and (min-width: 1024px) {
  .page-footer__stores {
    width: 25%;
    padding: 0 5% 20px; }
  .page-footer__stores-block {
    width: auto;
    min-width: 190px; }
  .page-footer__nav {
    width: 50%;
    padding: 0 5%; }
  .page-footer__nav-list {
    width: 240px; }
  .page-footer__nav-link {
    font-size: 16px; }
  .page-footer__mode {
    width: 25%;
    padding: 0 5%; }
  .page-footer__mode-block {
    margin: 0; } }
